import { Box, TextField } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import {
  datePickerStyle,
  inputStyle
} from "../styles/styles";


export default function SplitDateTimePicker({ dateTime, setDateTime }) {

  const [initialDateTime, setInitialDateTime] = React.useState(dateTime);

  const handleDateChange = (newDate) => {
    if (newDate) {
      setDateTime(newDate.hour(initialDateTime.hour()).minute(initialDateTime.minute()));
      setInitialDateTime(newDate);
    }
  };

  const handleTimeChange = (newTime) => {
    if (newTime) {
      setDateTime(initialDateTime.hour(newTime.hour()).minute(newTime.minute()));
      setInitialDateTime(newTime);
    }
  };

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="en-gb"
        localeText={{
          okButtonLabel: "Save",
          todayButtonLabel: "Set date & time to now",
        }}
      >
        <Box display="flex" gap={2}
          sx={{
            ...inputStyle,
            ...datePickerStyle,
            paddingTop: "0px",
            paddingBottom: "0px",
          }}

        >
          <DatePicker
            value={dateTime}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
            slotProps={{
              inputAdornment: { position: "start" },
            }}
          />
          <TimePicker
            value={dateTime}
            onChange={handleTimeChange}
            slotProps={{
              inputAdornment: { position: "start" },
            }}
          />
        </Box>
      </LocalizationProvider>
    </>
  );
}
