import dayjs from "dayjs";
import React from "react";
import SplitDateTimePicker from "../dates/SplitDateTimePicker";
import {
  inputLabelStyle,
  inputStyle
} from "../styles/styles";


export default function TagStartDatePicker({ dateTimeStart, setDateTimeStart }) {

  const tagStartDateEditable = true;

  return (
    <>
      <label htmlFor="startdate" style={inputLabelStyle}>
        Worn since
      </label>
      <br />
      {tagStartDateEditable ? (
        <>
          <SplitDateTimePicker dateTime={dateTimeStart} setDateTime={setDateTimeStart} />
        </>
      ) : (
        <input
          type="text"
          id="startdate"
          name="startdate"
          disabled
          value={
            (dateTimeStart ? dayjs(dateTimeStart) : dayjs(new Date()))
              .format("hh:mm on dddd, DD MMMM YYYY")
          }
          style={inputStyle}
        />
      )}
    </>
  );
}
